/* General Theme */
body {
  font-family: 'Roboto', sans-serif;
  color: #333333;
  background-color: #F2F2F2;
  margin: 0;
  padding: 0;
}

/* Base Button Styles */
.btn {
  display: flex; /* Flexbox for centering text */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  font-size: 1rem;
  border-radius: 5px;
  padding: 12px 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
}

/* Primary Button */
.btn-primary {
  background-color: #FF5500;
  color: #FFFFFF;
  border: 2px solid #FF6600;
}

.buton3{
  background-color: #FF9a00;
  color: #FFFFFF;
  border: 2px solid #FF6600;

}

.btn-primary:hover {
  background-color: #E65C00;
  border-color: #E65C00;
}

/* Active button styles */
.btn-primary.active {
  background-color: #FF6600; /* Maintain active color */
  color: #FFFFFF;
  border-color: #FF6600;
}

/* Success Button (WhatsApp) */
.btn-success {
  background-color: #25D366;
  color: #FFFFFF;
  border: 2px solid #25D366;
}

.btn-success:hover {
  background-color: #1EBE5A;
  border-color: #1EBE5A;
}

/* Outline Primary Button */
.btn-outline-primary {
  background-color: #FFFFFF;
  color: #FF6600;
  border: 2px solid #FF6600;
}

.btn-outline-primary:hover {
  background-color: #FF6600;
  color: #FFFFFF;
}

/* Small button styles */
.btn-sm {
  padding: 6px 12px;
  font-size: 0.875rem;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-sm:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(255, 102, 0, 0.3);
  -webkit-box-shadow: 0 0 8px rgba(255, 102, 0, 0.3);
}

/* Secondary button (e.g., add row) */
.btn-secondary {
  background-color: #F2F2F2;
  border-color: #E0E0E0;
  color: #333333;
  padding: 5px 10px;
  transition: all 0.3s ease;
  font-size: 14px;
}

.btn-secondary:hover {
  background-color: #E0E0E0;
  border-color: #D0D0D0;
  color: #000000;
}

.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary:visited {
  background-color: #E0E0E0;
  border-color: #D0D0D0;
  color: #000000;
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}

/* Courier Button Styles */
.courier-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.courier-buttons .btn {
  font-size: 16px;
  padding: 10px 30px;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #FF6600;
  border: 2px solid #FF6600;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center;
  cursor: pointer;
}

.courier-buttons .btn:hover {
  background-color: #FF6600;
  color: #FFFFFF;
  border-color: #FF6600;
}

/* Specific styles for buttons in the "cotizacion" container */
.cotizacion .btn {
  width: 50%;
  max-width: 300px;
  margin: 5px;
  padding: 8px 16px;
  font-size: 1rem;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-align: center;
  height: 60px; /* Consistent height */
}

.cotizacion .btn-secondary {
  background-color: #E0E0E0;
  color: #333333;
  border-color: #D0D0D0;
}

.cotizacion .btn-secondary:hover {
  background-color: #D0D0D0;
  color: #000000;
}

.cotizacion .btn-primary {
  background-color: #FF6600;
  color: #FFFFFF;
  border-color: #FF6600;
}

.cotizacion .btn-primary:hover {
  background-color: #E65C00;
  border-color: #E65C00;
}

.cotizacion .btn-success {
  background-color: #25D366;
  color: #FFFFFF;
  border-color: #25D366;
}

.cotizacion .btn-success:hover {
  background-color: #1EBE5A;
  border-color: #1EBE5A;
}

/* Override default browser styles for focus, active, and visited states */
.btn-primary:focus,
.btn-primary:active,
.btn-primary:visited,
.btn-success:focus,
.btn-success:active,
.btn-success:visited {
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .contact-info {
    display: block;
  }

  .shipping-table {
    display: none;
  }

  .shipping-table.active {
    display: block;
  }

  .contact-info.inactive {
    display: none;
  }

  .courier-buttons, .cotizacion .d-flex {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 20px;
  }

  .courier-buttons .btn,
  .cotizacion .btn {
    width: 100%;
    font-size: 18px;
    padding: 14px 28px;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
  }

  .courier-buttons .btn:hover,
  .cotizacion .btn:hover {
    background-color: #FF6600;
    color: #FFFFFF;
    border-color: #FF6600;
    transform: translateY(-2px);
  }
}

/* Form Inputs */
.form-control:focus {
  border-color: #FF6600;
  box-shadow: 0 0 5px rgba(255, 102, 0, 0.5);
  -webkit-box-shadow: 0 0 5px rgba(255, 102, 0, 0.5);
}

/* Form Layout */
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 30px;
}

.form-group {
  flex: 0 0 48%;
  max-width: 48%;
}

.form-group input {
  width: 100%;
}

/* Table Styling */
.table {
  width: 100%;
  border-collapse: collapse;
}

.table thead th {
  background-color: #F2F2F2;
  border-bottom: 2px solid #FF6600;
  text-align: left;
}

.table td, .table th {
  border: 1px solid #E0E0E0;
  padding: 8px;
  word-wrap: break-word;
}

/* Section Headers */
h3, h4 {
  color: #FF6600;
  font-weight: bold;
}

/* Icons */
.fa-whatsapp {
  color: #25D366;
}

.fa {
  color: #FF6600;
}

/* Backgrounds and Containers */
.form-holder, .table-responsive {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .form-holder, .table-responsive {
    padding: 5px;
    margin: 10px auto;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .table {
    display: block;
    overflow-x: auto;
  }

  .table thead {
    display: none;
  }

  .table tr {
    display: block;
    margin-bottom: 10px;
  }

  .table td {
    display: block;
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 70%;
    padding-left: 10px;
    font-weight: bold;
    text-align: left;
  }
}

/* Larger screen styles */
@media (min-width: 1201px) {
  .table-responsive .table thead {
    display: table-header-group !important;
  }
  .table-responsive .table tbody tr {
    display: table-row;
  }
}

/* Navbar */
.navbar-nav .nav-link.active {
  color: #FF6600;
}

.navbar {
  background-color: #FFFFFF;
  border-bottom: 2px solid #FF6600;
}

/* Miscellaneous */


/* Custom modal styles */
.custom-modal .modal-content {
  background-color: #f8f9fa;
  border-radius: 10px;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.custom-modal .modal-header {
  background-color: #FF6600;
  color: white;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-modal .modal-body {
  padding: 20px;
  font-size: 16px;
}

.custom-modal .modal-footer {
  border-top: none;
  padding-top: 0;
}

.custom-modal .btn-primary {
  background-color: #FF6600;
  border-color: #FF6600;
  color: white;
  transition: background-color 0.3s ease;
}

.custom-modal .btn-primary:hover {
  background-color: #E65C00;
}

.custom-modal .btn-primary:focus,
.custom-modal .btn-primary:active,
.custom-modal .btn-primary:visited {
  background-color: #E65C00;
  border-color: #E65C00;
  color: white;
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}

/* Cotization Specific Styles */
.container.cotizacion {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  margin-top: 50px;
}

.quote-header {
  color: #FF6600;
  font-weight: bold;
}

.quote-item .title-rate {
  color: #333333;
}

.quote-item .value-rate {
  color: #333333;
  font-weight: bold;
}

.quote-total .total {
  color: #FF6600;
  font-weight: bold;
}

.total-value {
  font-size: 1.5rem;
  color: #FF6600;
}

/* Adjust button spacing */
.d-flex .btn {
  margin-right: 8px;
}

.d-flex .btn:last-child {
  margin-right: 0;
}

/* Remove spinner arrows in n input fields */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .cotizacion .d-flex {
    flex-direction: column; /* Stack buttons vertically on mobile */
    align-items: center; /* Center align buttons on mobile */
    gap: 10px; /* Add a gap between buttons */
  }

  .cotizacion .btn {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 5px;
    transition: all 0.3s ease;
    text-align: center;
  }

  .cotizacion .btn:hover {
    transform: translateY(-2px); /* Slight elevation on hover */
  }
}

/* Cotización Buttons Styles for Desktop */
.cotizacion .d-flex .btn {
  width: 50%;
  max-width: 300px;
  margin: 5px;
  padding: 8px 16px;
  font-size: 1rem;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-align: center;
  height: 60px;
}

/* Specific styles to prevent excessive button height on large screens */
@media (min-width: 769px) {
  .cotizacion .btn {
    padding: 8px 16px;
    font-size: 1rem;
  }
}
.courier-buttons .btn.active {
  background-color: #FF6600; /* Maintain active color */
  color: #FFFFFF;
  border-color: #FF6600;
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  input[type="number"] {
    width: 70%; /* Adjust width to 90% to make it larger */
    margin: 0 auto; /* Center align if needed */
    padding: 10px; /* Increase padding for better usability */
    font-size: 16px; /* Larger font for better readability */
  }
}
/* Background Image for a Specific Section */
.decorative-image {
  position: fixed; /* Use fixed positioning to cover the entire width */
  top: 0;
  left: 0;
  width: 100%; /* Cover the full width of the page */
  height: auto; /* Maintain aspect ratio */
  z-index: -1; /* Ensure it is behind other content */
  opacity: 0.8; /* Adjust opacity for a decorative effect */
  object-fit: cover; /* Ensures the image covers the container proportionally */
  pointer-events: none; /* Prevents interaction with the image */
  filter: blur(10px); /* Blur effect */
  border-top: 5px solid rgba(255, 255, 255, 0.5); /* Top border with transparency */
  border-bottom: 5px solid rgba(255, 255, 255, 0.5); /* Bottom border with transparency */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
 
}

/* Media queries for responsive adjustments */
@media (max-width: 1200px) {
  .decorative-image {
    filter: blur(8px); /* Adjust blur intensity for medium screens */
  }
}

@media (max-width: 992px) {
  .decorative-image {
    opacity: 0.7; /* Slightly reduce opacity for a subtle effect */
    filter: blur(6px); /* Adjust blur intensity for smaller screens */
  }
}

@media (max-width: 768px) {
  .decorative-image {
    opacity: 0.6; /* Further reduce opacity for a subtle background effect */
    filter: blur(4px); /* Adjust blur intensity for tablets */
  }
}

@media (max-width: 576px) {
  .decorative-image {
    opacity: 0.5; /* Reduce opacity more for a softer look */
    filter: blur(2px); /* Minimal blur for mobile */
  }
}
.header {
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  position: relative; /* To position the logo container absolutely relative to the header */
}

.header-left {
  flex: 0 0 auto; /* Keep the logo container at its width */
  display: flex;
  align-items: center;
  padding-left: 10px; /* Adjust padding if needed */
}

.logo-container {
  margin-right: 50px; 
  margin-left: 50px;/* Adds space between the logo and the next content */
}

.logo {
  max-height: 70px;
  max-width: 100%;
}

.header-right {
  flex: 1; /* Make the right part of the header take the rest of the space */
  display: flex;
  flex-direction: column;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically */
  width: 100%;
  background-color: #797C7E;
  padding: 10px 20px;
  height: 50px;
}

.contact-info {
  font-size: 14px;
}



.header-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 0px;
  height: 80px;

}

.nav-menu {
  display: flex;
  align-items: center; /* Aligns items vertically to the center */
  gap: 40px; /* Adds more space between the items */
  margin-left: auto; /* Pushes the menu items to the right */
  height: 100%; /* Ensure the nav menu takes up full height */
}


.nav-menu a {
  text-decoration: none;
  color: #797C7E;
}


.search-icon:hover {
  background-color: #f0f0f0; /* Change background on hover */
}



.cta-buttons {
  display: flex;
  margin-left: 20px;
}

.cta-button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.quote1,
.register1 {
  background-color: #ff6600;
  color: #fff;
}

.search-bar {
  margin-left: 20px;
}

.search-input {
  padding: 5px;
  font-size: 14px;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .header-right {
    width: 100%;
  }

  .header-bottom {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-menu {
    display: none; /* Hide nav menu in mobile */
    flex-direction: column;
    width: 100%;
  }

  .nav-menu.show {
    display: flex; /* Show nav menu on toggle */
  }

  .hamburger-menu {
    display: block; /* Show hamburger menu icon */
  
  }

  .cta-buttons,
  .search-bar {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.contact-item {
  flex-direction: column;
  justify-content: space-between;
  color: WHITE; /* Ensures all text inside is white */
  border-right: 0.5px solid rgb(182, 176, 176); /* Adds border on the right */
  padding: 15px 15px; /* Adds some padding to the left and right, and increase padding for more space */


}

.search-icon {
  display: flex; /* Changed to 'flex' to ensure full control */
  align-items: center; /* Vertically centers the content */
  justify-content: center; /* Horizontally centers the content */
  padding-left: 30px;
  padding-right: 15px;
  border-left: 0.5px solid rgb(182, 176, 176);
height: 80px;
  background-color: transparent;
  cursor: pointer;
  color: rgb(132, 126, 126);
  font-size: 18px;
  transition: background-color 0.3s;
  box-sizing: border-box;
}

.search-icon:hover {
  background-color: #f0f0f0; /* Add a background color on hover */
}

.header-bottom .nav-menu .nav-link {
  text-decoration: none;
 
  margin-right: 20px;
  transition: color 0.3s ease;
}

.header-bottom .nav-menu .nav-link:hover {
  color: #FF5F00; /* Hover color */
}

.search-icon:hover {
  color: #FF5F00; /* Hover color for search icon */
}



/* Media query for mobile view */
/* Media query for mobile view */
/* Ensure hamburger is aligned to the right in mobile */
@media (max-width: 768px) {
  .header-bottom {
    display: flex;
    justify-content: flex-end; /* Push hamburger to the right */
    align-items: center;
    width: 100%; /* Ensure it spans full width */
  }

  .hamburger-menu {
    display: block; /* Show hamburger in mobile */
    cursor: pointer;
    font-size: 24px;
  }

  .nav-menu {
    display: none; /* Hide nav menu initially */
    flex-direction: column;
    width: 80%;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
    backdrop-filter: blur(10px); /* Apply background blur */
    position: fixed; /* Make the menu fixed to the screen */
    top: 80px; /* Adjust to the height of your header */
    left: 50%; /* Move it 50% from the left */
    transform: translateX(-50%); /* Center it horizontally */
    padding: 10px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 9999; /* Ensure it is above all other content */
    height: 80%;
    overflow-y: auto; /* Optional: to make the menu scrollable if it overflows */
  }
  

  .nav-menu.show {
    display: flex; /* Show nav menu when toggled */
  }

  .nav-link {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    z-index: 9999; /* Ensure nav links are on top */
  }

}


.footer {
  background-color: #797C7E;
  color:#CCCCCC;
  padding: 100px;
  height: 60vh;
  position: relative; /* Make the footer a relative container */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space content inside footer */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1; /* Ensures it expands to fill the available space */
}

.footer-left {
  flex: 1;
  min-width: 250px;
}

.footer-logo {
  width: 100px;
  margin-bottom: 10px;
}

.footer-options, .footer-links, .footer-contact {
  flex: 1;
  min-width: 200px;
}

.footer-options h3, .footer-links h3, .footer-contact h3 {
  margin-bottom: 15px;
  font-size: 28px;
  color: white;
}

.footer-options ul, .footer-links ul {
  list-style-type: none;
  padding: 0;
}

.footer-options li, .footer-links li {
  margin: 5px 0;
}

.footer-contact p {
  margin: 5px 0;
}

.footer-bottom {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  padding-right: 20px;
  background-color: #777171;
  align-items: center; /* Vertically center items */
  padding-bottom: 0;

  position: absolute; /* Stick to the bottom of the footer */
  bottom: 0;
  left: 0;
  width: 100%; /* Span the full width */
  height: 40px; /* Define height if necessary */
}
.footer-bottom p {
 color: white;
}

.whatsapp-icon {
  width: 40px;
}

/* Custom list styles */
ul li {
  list-style: none;
  position: relative;
  padding-left: 20px;
}

ul li::before {
  content: "•";
  position: absolute;
  left: 0;
  transition: color 0.3s ease;
}

ul li:hover::before {
  color: #FF6600;
}

ul li:hover {
  color: #ff5733;
  cursor: pointer;
}

ul li a:hover {
  color: #ff5733;
}

/* Contact styles */
.footer-contact {
  color:#CCCCCC;
}

.footer-contact a {
  color:#CCCCCC;
  text-decoration: none;
}

/* Navigation link styles */
.nav-link a {
  color: red !important;
  text-decoration: none !important;
}

.nav-link a:visited {
  color: red !important;
}

.nav-link a:hover {
  color: darkred !important;
}

.nav-link a:active {
  color: red !important;
}

a {
  color: inherit;
  text-decoration: none;
}
@media (max-width: 768px) {
  .footer {
    height: 80vh; /* Increase the height for mobile devices */
    padding: 50px; /* Adjust the padding for better layout on smaller screens */
  }

  .footer-bottom {
    height: 60px; /* Adjust the footer-bottom height */
  }
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  /* Adjust footer height and padding */
  .footer {
    height: auto; /* Auto height so it adjusts dynamically */
    padding: 60px 20px; /* More padding for mobile */
  }

  /* Adjust footer-bottom height */
  .footer-bottom {
    height: 70px; /* Adjust height for better appearance on mobile */
    justify-content: center; /* Center the content horizontally */
  }

  /* Hide header-top class on mobile */
  .header-top {
    display: none; /* Hide on screens smaller than 768px */
  }

  /* Footer logo centering on mobile */
  .footer-logo {
    margin: 0 auto 20px; /* Center the logo with some bottom margin */
  }

  /* Footer items centering on mobile */
  .footer-container {
    flex-direction: column; /* Stack items vertically */
    text-align: center; /* Center the text */
  }

  .footer-options, .footer-links, .footer-contact {
    margin-bottom: 20px; /* Add space between sections */
  }

  .footer-contact {
    padding: 0 20px; /* Add side padding for better spacing */
  }

  /* Hide bullet points in the footer lists */
  .footer ul {
    list-style-type: none; /* Removes bullet points */
    padding-left: 0; /* Removes the default left padding */
  }
}

/* Ensure the table takes up full width and can scroll on mobile */
.table-responsive {
  width: 100%;
  overflow-x: auto;
}

.table-bordered {
  width: 100%; /* Make the table take full width */
  min-width: 400px; /* Minimum width to ensure content doesn't get squeezed */
  border-collapse: collapse; /* Ensure borders collapse properly */
}

/* Adjustments specifically for mobile view */
@media (max-width: 768px) {
  .table-responsive {
    overflow-x: auto; /* Enable horizontal scrolling */
  }

  .table-bordered {
    width: 90%; /* Ensure the table takes the full width of the mobile screen */
    display: block; /* Make the table display as a block element for better responsiveness */
  }

  /* Ensure table cells stack properly */
  .table-bordered td, 
  .table-bordered th {
    white-space: nowrap; /* Prevent table data from wrapping */
  }
}
